















import {Component, Vue} from "vue-property-decorator";
import {AccountApi} from "@/api/v1/AccountApi";
import {Action, Getter} from "vuex-class";
import {UserIdentity} from "@/store/types";

@Component
export default class Login extends Vue {
    //Data
    error: string | null = null;

    @Getter getIdentity!: UserIdentity;
    @Action updateEmail!: (email: string) => any;

    created() {
        if (this.getIdentity.isAuthenticated) {
            this.$router.push({name: "overview"});
        } else {
            const params = this.$route.query;
            const code = params["code"] as string | undefined;
            // const state = params["state"] as string | undefined;
            if (code !== "" && code !== undefined) {
                //TODO check the state

                //TODO handle invalid code
                AccountApi.auth(code)
                    .then(() => {
                        this.$router.push({name: "overview"});
                    });
            } else {
                AccountApi.getAuthSettings()
                    .then(response => {
                        window.location.href = `${response.url}/login?client_id=${response.clientId}`;
                    });
            }
        }
    }
}
